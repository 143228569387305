import React from "react"
import { Link, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"

const PortfolioPage = ({ data }) => {
  const { edges: posts } = data.allMdx

  return (
    <Layout pageTitle="My Blog Posts">
      {posts.map(({ node: post }) => (
        <article key={post.id}>
          <Link to={post.fields.slug}>
            <h2>{post.frontmatter.title}</h2>
          </Link>
          <p>{post.excerpt}</p>
          <p>Posted: {post.frontmatter.date}</p>
          {/* <MDXRenderer>{post.body}</MDXRenderer> */}
        </article>
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query portfolioIndex {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default PortfolioPage
